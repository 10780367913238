import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { GET_PARTNER_FOR_USER } from '../queries/getPartnerForUser'
import { Partner } from '../../../packages/depot-banquet-props/src'
import { DEPOT_FEATURE_FLAGS } from '../../../packages/depot-banquet-props/src/lib/DEPOT_FEATURE_FLAGS'

const queryPartnerForUser = async ({
  query
}: ApolloClient<NormalizedCacheObject>) => {
  return query<{ getPartnerForUser: Partner }>({
    query: GET_PARTNER_FOR_USER,
    variables: { featureFlags: DEPOT_FEATURE_FLAGS }
  }).then((response) => response.data.getPartnerForUser)
}

export const getPartnerForUser = async (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return queryPartnerForUser(apolloClient).then((partner) => partner)
}
