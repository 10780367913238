export enum spaList {
  'DEPOT-LAYOUT-SPA' = 'tpc-depot-layout-spa',
  'SPA-USER-VERIFICATION' = 'spa-depot-user-verification'
}

interface SpaMap {
  [key: string]: string
}
// no longer valid, need to clean up
export const spaRoutes: SpaMap = {
  'tpc-depot-layout-spa': '/v1',
  'spa-depot-user-verification': '/user-verification'
}
