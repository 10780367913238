import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { GET_PERMISSIONS } from '../queries/getPermissions'
import { Permission } from '../../../packages/depot-banquet-props/src'

const gqueryForPermissions = async (
  partnerGuids: string[],
  { query }: ApolloClient<NormalizedCacheObject>
) => {
  return query<{ getPermissions: [Permission] }>({
    query: GET_PERMISSIONS,
    variables: { partnerGuids }
  }).then((response) => {
    return response.data.getPermissions
  })
}

export const getPermissions = async (
  partnerGuids: string[],
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return gqueryForPermissions(partnerGuids, apolloClient).then(
    (permissions) => permissions
  )
}
