import { gql } from '@apollo/client'

export const GET_PARTNER_FOR_USER = gql`
  query getPartnerForUser($featureFlags: [String!]) {
    getPartnerForUser(featureFlags: $featureFlags) {
      guid
      name
      applicationName
      marketImageUrl
      iconUrl
      crossEnvGuid
      featureFlags {
        name
        enabled
      }
    }
  }
`
