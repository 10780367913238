import { gql } from '@apollo/client'

export const GET_PERMISSIONS = gql`
  query getPermissions($partnerGuids: [ID!]) {
    getPermissions(partnerGuids: $partnerGuids) {
      partner
      operation
      enabled
    }
  }
`
