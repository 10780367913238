import { InMemoryCache, ApolloClient } from '@apollo/client'

const serverUrl = '/api/service/depot-bff/v1/graphql'

export const getApolloClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    uri: serverUrl
  })
}
